import React from "react";

import Layout from "../components/layout";

import { graphql } from "gatsby";
import Img from "gatsby-image";

import { css } from "@emotion/core";

import HEAD from "../components/head";
import logo from "../images/redapple_Final_Lightgray.png";

const css_form_category_header = css`
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 6px;
  color: #c1c1c1;
`;

const ContactForm = ({ data }) => (
  <Layout showHome>
    <HEAD
      title="Contact us"
      keywords={[`red apple solutions contact us`, `education`, `red`, `apple`]}
    />
    <div>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0;
          //height: 100%;
        `}
      >
        <Img
          title="Chalkboard"
          alt="Chalkboard background image"
          //fluid={data.chalkboard.childImageSharp.fluid}
          fluid={data.chalkboard.childImageSharp.fluid}
          css={css`
            position: inherit !important;
          `}
        />
        {/*
      <div
        //bg with paralax transform
        //data-parallax="true"
        css={css`
          height: 90vh;
          overflow: hidden;
          position: relative;
          background: url(${bg_image});
          background-position: 50%;
          background-size: cover;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: center;
          background-color: lightgray;
        `}
      >
      */}
        <div
          className="container"
          css={css`
            margin-top: 60px;
          `}
        >
          <div className="row">
            <div className="col-md">
              <img
                className="float-right"
                css={css`
                  width: 100%;
                  //max-width: 602px;
                  max-width: 200px;
                  min-width: 200px;

                  padding: 15px;
                  @media (max-width: 992px) {
                    float: none !important;
                  }
                `}
                src={logo}
                alt="Red Apple Solutions - brand"
              />
            </div>
            <div className="col-lg-8 mr-auto">
              <div
                css={css`
                  //background-color: white;
                `}
              >
                <form
                  name="contact"
                  method="POST"
                  action="/contact-form-submitted"
                  css={css`
                    padding: 25px;
                    background-color: white;
                    border-radius: 2px;
                    box-shadow: 0 6px 12px 5px rgba(0, 0, 0, 0.15);
                  `}
                  data-netlify="true"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div className="container">
                    <div className="row">
                      <div className="col-md-4">
                        <div css={css_form_category_header}>Your info</div>
                      </div>
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="first_name">First Name</label>
                          <input
                            id="first_name"
                            name="First Name"
                            type="text"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="last_name">Last Name</label>

                          <input
                            id="last_name"
                            name="Last Name"
                            type="text"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="job_title">Job Title</label>
                          <input
                            id="job_title"
                            name="Job Title"
                            type="text"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            id="email"
                            name="Email"
                            type="email"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div css={css_form_category_header}>Inquiry</div>
                      </div>
                      <div className="col-md">
                        <div className="form-group">
                          <label htmlFor="message" id="message">
                            How can we help you?
                          </label>
                          <textarea
                            id="message"
                            name="Message"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      className="form-group row"
                      css={css`
                        padding: 1rem;
                      `}
                    >
                      <div className="col-md" />
                      <div className="col-md">
                        <button
                          type="submit"
                          className="btn btn-outline-secondary btn-block uppercase"
                          css={css`
                            text-transform: uppercase;
                          `}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ContactForm;

export const query = graphql`
  query {
    chalkboard: file(relativePath: { eq: "chalkboard.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
